import { CustomSearchXStoreModule } from '../types';
import { adapter } from '../../../adapter/adapter';

/**
 * Default implementation for the {@link fetchStoreCounterResponse}.
 *
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchStoreResultsResponse: CustomSearchXStoreModule['actions']['fetchStoreResultsResponse'] =
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call

  (_context, request) => {
    return adapter.storeResults(request);
  };
