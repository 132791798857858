import { createFetchAndSaveActions } from '@empathyco/x-components';
import { InternalSearchRequest, SearchActions } from '@empathyco/x-components/search';
import { SearchRequest } from '@empathyco/x-types';
import { CustomSearchActionContext, CustomSearchState } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  CustomSearchActionContext,
  any | null,
  any | null
>({
  fetch({ dispatch, state }, request) {
    return request
      ? dispatch('fetchStoreResultsResponse' as keyof SearchActions, enrichRequest(request, state))
      : Promise.resolve(null);
  },
  onSuccess({ dispatch }, response) {
    if (response !== null) {
      dispatch('saveStoreResultsResponse', response);
    }
  }
});

/**
 * Enriches the {@link SearchRequest} object with the origin and page properties taken from the
 * {@link SearchState | search state}.
 *
 * @param request - The {@link InternalSearchRequest}.
 * @param state - {@link SearchState}.
 *
 * @returns The search request.
 * @internal
 */
function enrichRequest(request: InternalSearchRequest, state: CustomSearchState): SearchRequest {
  const { page, ...restRequest } = request;
  const {
    config: { carouselsNumber },
    origin,
    storeResults
  } = state;
  const start = page === 1 ? 0 : storeResults.length;

  return {
    ...restRequest,
    ...(origin && { origin }),
    start,
    rows: carouselsNumber * page - start
  };
}

/**
 * Implementation for {@link CustomSearchActionContext.fetchAndSaveStoreResultsResponse} action.
 *
 * @public
 */
export const fetchAndSaveStoreResultsResponse = fetchAndSave;

/**
 * Implementation for {@link CustomSearchActionContext.cancelFetchAndSaveStoreResultsResponse}.
 *
 * @public
 */
export const cancelFetchAndSaveStoreResultsResponse = cancelPrevious;
