import { createFetchAndSaveActions } from '@empathyco/x-components';
import { SearchActions } from '@empathyco/x-components/search';
import { CustomSearchActionContext } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  CustomSearchActionContext,
  any | null,
  any | null
>({
  fetch({ dispatch }, params) {
    return dispatch('fetchStoreInfoResponse' as keyof SearchActions, params);
  },
  onSuccess({ dispatch }, response) {
    if (response !== null) {
      dispatch('saveStoreInfoResponse', response);
    }
  }
});

/**
 * Implementation for {@link CustomSearchActionContext.fetchAndSaveStoreInfoResponse} action.
 *
 * @public
 */
export const fetchAndSaveStoreInfoResponse = fetchAndSave;

/**
 * Implementation for {@link CustomSearchActionContext.cancelFetchAndSaveStoreInfoResponse}.
 *
 * @public
 */
export const cancelFetchAndSaveStoreInfoResponse = cancelPrevious;
