import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { getEndpointRoute } from '../../utils/environment';
import { getLang } from '../../utils/lang';

/**
 * Returns the store counter endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getStoreCounterEndpoint(): string {
  const lang = getLang();
  return `${getEndpointRoute()}search/v1/query/mymotivemarketplace/store-counter?lang=${lang}`;
}

export const storeCounter = endpointAdapterFactory<any, any>({
  endpoint: getStoreCounterEndpoint(),
  responseMapper: schemaMapperFactory<any, any>({
    totalProducts: response => response.catalog.numFound ?? 300000,
    totalShops: response => response.catalog.counts.total_stores ?? 127
  })
});
