import { CustomSearchXStoreModule } from '../types';
import { adapter } from '../../../adapter/adapter';

/**
 * Default implementation for the {@link fetchStoreCounterResponse}.
 *
 * @param _context
 * @param request
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchExtraResultsResponse: CustomSearchXStoreModule['actions']['fetchExtraResultsResponse'] =
  (_context, request) => {
    if (request) {
      const refinedRequest = JSON.parse(JSON.stringify(request));
      if (refinedRequest.extraParams?.area) {
        delete refinedRequest.extraParams.area;
      }
      if (refinedRequest.extraParams?.store) {
        delete refinedRequest.extraParams.store;
      }
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(adapter.search(refinedRequest) as any);
        }, 0);
      });
    }
  };
