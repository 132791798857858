// Override the original SearchRequest Schema.
import {
  PlatformSemanticQueriesRequest,
  semanticQueriesRequestSchema
} from '@empathyco/x-adapter-platform';
import { SemanticQueriesRequest } from '@empathyco/x-types';

/**
 * Overrides {@link PlatformSemanticQueriesRequest} schema properties
 * with {@link SemanticQueriesRequest} extra properties.
 */
export const semanticQueriesRequestSchemaOverride = (): void => {
  semanticQueriesRequestSchema.$override<
    SemanticQueriesRequest,
    Partial<PlatformSemanticQueriesRequest>
  >({
    extraParams: ({ extraParams }) => {
      return {
        extraParams,
        filter_ids: 'NOT_ALL_WORDS,NOT_PARTIAL'
      };
    }
  });
};
