import VueRouter from 'vue-router';
import { useSeoComposable } from '../composables/use-seo.composable';
import routes from './routes';

const { setSeo } = useSeoComposable();

const router = new VueRouter({
  routes,
  mode: document.location.hostname.includes('test') ? 'hash' : 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.afterEach(to => {
  setSeo({
    path: to.path,
    store: (to.query.store as string) ?? '',
    query: (to.query.query as string) ?? ''
  });
});

export default router;
