import { CustomSearchXStoreModule } from '../types';
import { MyMotiveStore } from '../../../adapter/models/entitites/result';

// eslint-disable-next-line max-len
export const saveStoreInfoResponse: CustomSearchXStoreModule['actions']['saveStoreInfoResponse'] = (
  { commit },
  { storeInfo }: { storeInfo: MyMotiveStore[] }
) => {
  if (storeInfo.length === 1) {
    commit('setSelectedStoreInfo', storeInfo);
  } else {
    commit('setStoreInfo', storeInfo);
  }
};
