import { CustomSearchXStoreModule } from '../types';

// eslint-disable-next-line max-len
export const saveStoreResultsResponse: CustomSearchXStoreModule['actions']['saveStoreResultsResponse'] =
  ({ commit, state }, customstate) => {
    if (customstate.storeResults) {
      if (state.isAppendStoreResults) {
        commit('appendStoreResults', customstate.storeResults);
      } else {
        commit(
          'setIsEndOfStoreResultsInAnArea',
          customstate.storeNumFound <= state.page * state.config.carouselsNumber
        );
        commit('setStoreResults', customstate.storeResults);
        commit('setProductsPerShop', customstate.productsPerShop);
        commit('setStoreNumFound', customstate.storeNumFound);
      }
    }
    commit('setStoreTotalProducts', customstate.numFound ?? 0);
  };
