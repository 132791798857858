import { Dictionary } from '@empathyco/x-utils';

/**
 * Auxiliar function to get the environment.
 *
 * @returns The actual environment.
 */
export function getEnvironment(): string {
  const { hostname, search } = window.location;
  const urlParams = new URLSearchParams(search);
  const env = urlParams.get('env');

  if (env && ['live', 'staging'].includes(env)) {
    return env;
  }

  if (hostname === 'motivemarket.com') {
    return 'live';
  }
  return 'staging';
}

/**
 * Auxiliar function to get the endpoint route.
 *
 * @returns The actual route.
 */
export function getEndpointRoute(): string {
  const endpoint: Dictionary<string> = {
    live: process.env.VUE_APP_ENDPOINT_PROD,
    staging: process.env.VUE_APP_ENDPOINT_STAGING
  };

  const env = getEnvironment();

  return endpoint[env];
}
