import { XModule } from '@empathyco/x-components';
import { relatedPromptsXStoreModule } from './store/module';
import { RelatedPromptsXStoreModule } from './store/types';
import { relatedPromptsEmitters } from './store';
import { relatedPromptsWiring } from './wiring';

/**
 * Categories {@link XModule} alias.
 *
 * @public
 */
export type RelatedPromptsXModule = XModule<RelatedPromptsXStoreModule>;

/**
 * Categories {@link XModule} implementation. This module is auto-registered as soon as you
 * import any component from the `categories` entry point.
 *
 * @public
 */
export const RelatedPromptsXModule: RelatedPromptsXModule = {
  name: 'relatedPrompts',
  storeModule: relatedPromptsXStoreModule,
  storeEmitters: relatedPromptsEmitters,
  wiring: relatedPromptsWiring
};
