import { CustomSearchXStoreModule } from '../types';

// eslint-disable-next-line max-len
export const saveStoreCounterResponse: CustomSearchXStoreModule['actions']['saveStoreCounterResponse'] =
  ({ commit }, { totalProducts, totalShops }) => {
    if (totalProducts && totalShops) {
      commit('setTotalProducts', totalProducts);
      commit('setTotalShops', totalShops);
    }
  };
