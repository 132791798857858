import { RelatedPromptsXStoreModule } from '../types';
import { adapter } from '../../../../adapter/adapter';

/**
 * Default implementation for the {@link RelatedPromptsActions.fetchRelatedPromptsResponse}.
 *
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchRelatedPromptsResponse: RelatedPromptsXStoreModule['actions']['fetchRelatedPromptsResponse'] =
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call

  (_context, payload) => {
    const query = payload?.query || payload?.request?.query || '';
    const store = payload?.store || payload?.request?.extraParams.store;
    const lang = payload?.lang || payload?.request?.extraParams.lang || 'es';

    if (store) {
      return adapter.relatedPrompts({
        query,
        store,
        lang
      });
    }
    return Promise.resolve(null);
  };
