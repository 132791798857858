import { Result } from '@empathyco/x-types';
import { request } from './getters/request.getter';
import { fetchStoreCounterResponse } from './actions/fetch-store-counter-response.action';
import { saveStoreCounterResponse } from './actions/save-store-counter-response.action';
import { fetchAndSaveStoreCounterResponse } from './actions/fetch-and-save-store-counter.action';
import { fetchAndSaveStoreResultsResponse } from './actions/fetch-and-save-store-results.action';
import { fetchStoreResultsResponse } from './actions/fetch-store-results-response.action';
import { saveStoreResultsResponse } from './actions/save-store-results-response.action';
import { fetchStoreInfoResponse } from './actions/fetch-store-info-response.action';
import { saveStoreInfoResponse } from './actions/save-store-info-response.action';
import { fetchAndSaveStoreInfoResponse } from './actions/fetch-and-save-store-info.action';
import { fetchStorePreviewResponse } from './actions/fetch-store-preview-response.action';
import { fetchAndSaveStorePreviewResponse } from './actions/fetch-and-save-store-preview.action';
import { saveStorePreviewResponse } from './actions/save-store-preview-response.action';
// eslint-disable-next-line max-len
import { fetchAndSaveExtraResultsResponse } from './actions/fetch-and-save-extra-results-response.action';
import { fetchExtraResultsResponse } from './actions/fetch-extra-results-response.action';
import { saveExtraResultsResponse } from './actions/save-extra-results-response.action';
import { CustomSearchState } from './types';
import { saveSearchResponse } from './actions/save-search-response.action';
import { increasePageAppendingCarousels } from './actions/increase-page-appending-carousels.action';
import { fetchSearchResponse } from './actions/fetch-search-response.action';

export const searchCustomStoreModule = {
  state: {
    totalProducts: '',
    totalShops: '',
    storeResults: [],
    productsPerShop: {},
    storeInfo: [],
    selectedStoreInfo: [],
    storePreview: [],
    extraResults: [],
    isAppendStoreResults: false,
    storeNumFound: undefined,
    isEndOfStoreResults: false
  },
  getters: {
    request
  },
  mutations: {
    appendStoreResults(state: CustomSearchState, storeResults: any) {
      state.storeResults = [...state.storeResults, ...storeResults];
    },
    setIsAppendStoreResults(state: CustomSearchState, isAppendStoreResults: boolean) {
      state.isAppendStoreResults = isAppendStoreResults;
    },
    setIsEndOfStoreResultsInAnArea(state: CustomSearchState, isEndOfStoreResults: boolean) {
      state.isEndOfStoreResults = isEndOfStoreResults;
    },
    setStoreResults(state: { storeResults: any }, storeResults: any) {
      state.storeResults = storeResults;
    },
    setStoreNumFound(state: CustomSearchState, storeNumFound: any) {
      state.storeNumFound = storeNumFound;
    },
    setStoreTotalProducts(state: { storeTotalProducts: any }, storeTotalProducts: any) {
      state.storeTotalProducts = storeTotalProducts;
    },
    setExtraResults(state: CustomSearchState, extraResults: Result[]) {
      const filteredExtraResults = extraResults.filter(
        (extraItem: any) => !state.results.some((resultItem: any) => resultItem.id === extraItem.id)
      );
      state.extraResults = filteredExtraResults;
    },
    resetExtraResults(state: { extraResults: any }) {
      state.extraResults = [];
    },
    setStorePreview(state: { storePreview: any }, storePreview: any) {
      state.storePreview = storePreview;
    },
    setStoreInfo(state: { storeInfo: any }, storeInfo: any) {
      state.storeInfo = storeInfo;
    },
    setSelectedStoreInfo(state: { selectedStoreInfo: any }, selectedStoreInfo: any) {
      state.selectedStoreInfo = selectedStoreInfo;
    },
    setProductsPerShop(state: { productsPerShop: any }, productsPerShop: any) {
      state.productsPerShop = productsPerShop;
    },
    resetStoreResults(state: { storeResults: any }) {
      state.storeResults = [];
    },
    setTotalProducts(state: { totalProducts: string }, totalProducts: number) {
      let rounder = 1;

      if (totalProducts > 1000) {
        rounder = totalProducts > 100000 ? 10000 : 1000;
      }

      const roundedValue = Math.round(totalProducts / rounder) * rounder;
      state.totalProducts = roundedValue.toLocaleString('es');
    },
    setTotalShops(state: { totalShops: string }, totalShops: number) {
      state.totalShops = totalShops.toString();
    },
    resetSingleShopState(state: {
      singleShopResults: Result[];
      singleShopQuery: string;
      singleShopName: string;
      isSingleShopNoResults: boolean;
    }) {
      state.singleShopResults = [];
      state.singleShopQuery = '';
      state.singleShopName = '';
      state.isSingleShopNoResults = false;
    }
  },
  actions: {
    fetchStoreCounterResponse,
    saveStoreCounterResponse,
    fetchAndSaveStoreCounterResponse,
    fetchStoreResultsResponse,
    saveStoreResultsResponse,
    fetchAndSaveStoreResultsResponse,
    fetchStoreInfoResponse,
    saveStoreInfoResponse,
    fetchAndSaveStoreInfoResponse,
    fetchAndSaveStorePreviewResponse,
    fetchStorePreviewResponse,
    saveStorePreviewResponse,
    fetchAndSaveExtraResultsResponse,
    fetchExtraResultsResponse,
    saveExtraResultsResponse,
    saveSearchResponse,
    increasePageAppendingCarousels,
    fetchSearchResponse
  }
};
