import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { getTaggingInfoFromUrl } from '@empathyco/x-adapter-platform';
import { getEndpointRoute } from '../../utils/environment';
import { getEncodedSignature } from '../../utils/encryption';
import { MyMotiveResult } from '../models/entitites/result';
import { getProxyServiceURL } from '../mappers/schemas/results';
import { getLang } from '../../utils/lang';

/**
 * Returns the store counter endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getStorePreviewEndpoint(): string {
  const lang = getLang();
  return `${getEndpointRoute()}search/v1/query/mymotivemarketplace/searchnoq?lang=${lang}`;
}

export const storePreview = endpointAdapterFactory<any, any>({
  endpoint: getStorePreviewEndpoint(),
  responseMapper: schemaMapperFactory<any, any>({
    storePreview: response => storePreviewMapper(response.catalog.content as MyMotiveResult[])
  })
});

/**
 * Maps result images by prepending the proxy service URL to each one.
 *
 * @param image - The images to map.
 * @returns A mapped images.
 */
function mapImage(image: string): string {
  if (image.includes('imgproxy')) {
    return image;
  }
  const proxyURL = getProxyServiceURL();
  return proxyURL + getEncodedSignature(image);
}

/**
 * Maps the store results from the response.
 *
 * @param results - The results array.
 * @returns The mapped store results.
 */
function storePreviewMapper(results: MyMotiveResult[]): any {
  results.forEach(result => {
    if (result.images) {
      result.images = result.images.map((image: string) => mapImage(image));
    }

    if (typeof result.price === 'number') {
      result.price = {
        value: result.price,
        originalValue: result.previousPrice,
        hasDiscount: !!result.previousPrice && result.previousPrice > result.price
      };
    }

    if (result.tagging) {
      result.tagging = {
        add2cart: getTaggingInfoFromUrl(result.tagging.add2cart as string),
        checkout: getTaggingInfoFromUrl(result.tagging.checkout as string),
        click: getTaggingInfoFromUrl(result.tagging.click as string),
        displayClick: getTaggingInfoFromUrl(result.tagging.displayClick as string)
      };
    }
  });
  return results;
}
