import {
  FacetConfig,
  facetSchema,
  getFacetConfig,
  PlatformFacet,
  PlatformFacetType
} from '@empathyco/x-adapter-platform';
import { NumberRangeFacet } from '@empathyco/x-types';
import { MapperContext } from '@empathyco/x-adapter';

const getFacetConfigWithEditable = (facetId: string, type: PlatformFacetType): FacetConfig => {
  if (facetId === 'price') {
    const priceConfig = getFacetConfig('editable-range');
    const getPriceFilterId = (context?: MapperContext): string | undefined =>
      (context?.requestParameters?.filter as string[])?.find(filter => filter.includes('price'));

    priceConfig.schema = {
      ...priceConfig.schema,
      id: ({ filter }, $context) => {
        const currentPriceFilterId = getPriceFilterId($context);

        return currentPriceFilterId ?? filter;
      },
      label: (_, $context) => getPriceFilterId($context)?.split(':')[1],
      range: ({ value }, $context) => {
        const currentPriceFilterId = getPriceFilterId($context);

        const stringRage = currentPriceFilterId?.split(':')[1] ?? (value as string);

        const min = Number(stringRage.split('-')[0]);
        const max = Number(stringRage.split('-')[1]);

        return {
          min: Number.isNaN(min) ? null : min,
          max: Number.isNaN(max) ? null : max
        };
      }
    };

    return priceConfig;
  }

  return getFacetConfig(type);
};

export const facetSchemaOverride = (): void => {
  facetSchema.$override<PlatformFacet, Partial<NumberRangeFacet>>({
    modelName: ({ type, facet }) => getFacetConfigWithEditable(facet, type).modelName as any,
    filters: {
      $path: 'values',
      $subSchema: ({ type, facet }) => getFacetConfigWithEditable(facet, type).schema,
      $context: {
        test: 'test'
      }
    }
  });
};
