import { createFetchAndSaveActions } from '@empathyco/x-components';
import { SearchActions } from '@empathyco/x-components/search';
import { CustomSearchActionContext } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  CustomSearchActionContext,
  any | null,
  any | null
>({
  fetch({ dispatch }) {
    return dispatch('fetchStoreCounterResponse' as keyof SearchActions, undefined);
  },
  onSuccess({ dispatch }, response) {
    if (response !== null) {
      dispatch('saveStoreCounterResponse', response);
    }
  }
});

/**
 * Implementation for {@link CustomSearchActionContext.fetchAndSaveStoreCounterResponse} action.
 *
 * @public
 */
export const fetchAndSaveStoreCounterResponse = fetchAndSave;

/**
 * Implementation for {@link CustomSearchActionContext.cancelFetchAndSaveStoreCounterResponse}.
 *
 * @public
 */
export const cancelFetchAndSaveStoreCounterResponse = cancelPrevious;
