import { XPlugin } from '@empathyco/x-components';
import { CustomSearchXStoreModule } from '../types';

/**
 * Default implementation for the {@link SearchActions.fetchSearchResponse}.
 *
 * @param _context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 * @param request - The search request to make.
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
export const fetchSearchResponse: CustomSearchXStoreModule['actions']['fetchSearchResponse'] = (
  _context,
  request
) => {
  const { area, store, ...customExtraParams } = request.extraParams ?? {};
  const customRequest = {
    ...request,
    extraParams: store
      ? {
          ...customExtraParams,
          store
        }
      : {
          ...customExtraParams,
          area
        }
  };

  return XPlugin.adapter.search(customRequest);
};
