import { createFetchAndSaveActions } from '@empathyco/x-components';
import { SearchActions } from '@empathyco/x-components/search';
import { CustomSearchActionContext } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  CustomSearchActionContext,
  any | null,
  any | null
>({
  fetch({ dispatch }, store) {
    return store
      ? dispatch('fetchStorePreviewResponse' as keyof SearchActions, store)
      : Promise.resolve(null);
  },
  onSuccess({ dispatch }, response) {
    if (response !== null) {
      dispatch('saveStorePreviewResponse' as keyof SearchActions, response);
    }
  }
});

/**
 * Implementation for {@link CustomSearchActionContext.fetchAndSaveStorePreviewResponse} action.
 *
 * @public
 */
export const fetchAndSaveStorePreviewResponse = fetchAndSave;

/**
 * Implementation for {@link CustomSearchActionContext.cancelFetchAndSaveStorePreviewResponse}.
 *
 * @public
 */
export const cancelFetchAndSaveStorePreviewResponse = cancelPrevious;
