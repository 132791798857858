import { createFetchAndSaveActions } from '@empathyco/x-components';
import { RelatedPromptsActionContext } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  RelatedPromptsActionContext,
  any | null,
  any | null
>({
  fetch({ dispatch }, request) {
    return request ? dispatch('fetchRelatedPromptsResponse', request) : Promise.resolve(null);
  },
  onSuccess({ dispatch }, response) {
    if (response !== null) {
      dispatch('saveRelatedPromptsResponse', response);
    }
  }
});

/**
 * Implementation for {@link RelatedPromptsActionContext.fetchAndSaveStoreResultsResponse} action.
 *
 * @public
 */
export const fetchAndSaveRelatedPromptsResponse = fetchAndSave;

/**
 * Implementation for {@link RelatedPromptsActionContext.cancelFetchAndSaveStoreResultsResponse}.
 *
 * @public
 */
export const cancelFetchAndSaveRelatedPromptsResponse = cancelPrevious;
