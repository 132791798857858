import { PlatformAdapter, platformAdapter } from '@empathyco/x-adapter-platform';
import {
  bannerSchemaOverride,
  promotedSchemaOverride,
  resultSchemaOverride
} from './mappers/schemas/results';
import { recommendationsRequestSchemaOverride } from './mappers/schemas/recommendations';
import { semanticQueriesRequestSchemaOverride } from './mappers/schemas/semantic-queries';
import { facetSchemaOverride } from './mappers/schemas/facets';
import { relatedPrompts } from './endpoints/relatedPrompts';
import { storeCounter } from './endpoints/store-counter';
import { storeResults } from './endpoints/store-results';
import { storeInfo } from './endpoints/store-info';
import { storePreview } from './endpoints/store-preview';

/**
 * Initializing all schema overrides.
 *
 * @remarks This IIFE function is being executed immediately.
 */
(function initSchemaOverrides(): void {
  resultSchemaOverride();
  recommendationsRequestSchemaOverride();
  semanticQueriesRequestSchemaOverride();
  facetSchemaOverride();
  bannerSchemaOverride();
  promotedSchemaOverride();
})();

export const adapter: PlatformAdapter = {
  ...platformAdapter,
  relatedPrompts: relatedPrompts,
  storeCounter: storeCounter,
  storeResults: storeResults,
  storeInfo: storeInfo,
  storePreview: storePreview
};
