import { filter, InstallXOptions, SnippetConfig, wireService } from '@empathyco/x-components';
import { I18n, cssInjector } from '@empathyco/x-archetype-utils';
import VueRouter from 'vue-router';
import { addQueryToHistoryQueries } from '@empathyco/x-components/history-queries';
import { setUrlQuery } from '@empathyco/x-components/url';
import { DefaultFacetsService } from '@empathyco/x-components/facets';
import App from '../App.vue';
import * as messages from '../i18n/messages';
import store from '../store';
import { adapter } from '../adapter/adapter';
import { useDevice } from '../composables/use-device.composable';
import router from '../router';
import { searchCustomStoreModule } from '../x-modules/custom-search-module/module';
import { customSearchWiring } from '../x-modules/custom-search-module/wiring';
import { RelatedPromptsXModule } from '../x-modules/relatedPrompts';
import { CustomSearchState } from '../x-modules/custom-search-module/types';
import { queriesPreviewCustomStoreModule } from '../x-modules/custom-queries-preview-module/module';

const wireFacetsService = wireService(DefaultFacetsService.instance);
const clearFiltersWire = wireFacetsService('clearFilters');

const device = useDevice();

const addQueryToHistoryQueriesFiltered = filter(
  addQueryToHistoryQueries,
  ({ eventPayload }) => !eventPayload.startsWith('::')
);

const setUrlQueryFiltered = filter(
  setUrlQuery,
  ({ eventPayload }) => !eventPayload.startsWith('::')
);

export const installXOptions: InstallXOptions = {
  adapter,
  store,
  app: App,
  domElement: getDomElement,
  __PRIVATE__xModules: {
    search: {
      storeModule: searchCustomStoreModule as any,
      storeEmitters: {
        StoreResultsChanged: state => (state as CustomSearchState).storeResults
      }
    },
    queriesPreview: {
      storeModule: queriesPreviewCustomStoreModule as any
    }
  },
  xModules: {
    facets: {
      wiring: {
        ReceivedNoResultsFromQueryWithFilters: { clearFiltersWire }
      },
      config: {
        filtersStrategyForRequest: 'leaves-only'
      }
    },
    semanticQueries: {
      config: {
        threshold: 50,
        maxItemsToRequest: 10
      }
    },
    search: {
      wiring: customSearchWiring,
      config: {
        pageSize: 24,
        carouselsNumber: 4
      }
    },
    url: {
      wiring: {
        PageChanged: undefined,
        UserAcceptedAQuery: {
          setUrlQuery: setUrlQueryFiltered
        }
      }
    },
    historyQueries: {
      wiring: {
        UserAcceptedAQuery: {
          addQueryToHistoryQueries: addQueryToHistoryQueriesFiltered
        }
      }
    }
  },
  initialXModules: [RelatedPromptsXModule],
  async installExtraPlugins({ vue, snippet }) {
    const i18n = await I18n.create({
      locale: snippet.uiLang,
      device: (snippet.device as string) ?? device.deviceName.value,
      fallbackLocale: 'en',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      messages
    });
    vue.use(i18n);
    vue.use(VueRouter);
    vue.prototype.$setLocale = i18n.setLocale.bind(i18n);
    vue.prototype.$setLocaleDevice = i18n.setDevice.bind(i18n);

    return {
      i18n: i18n.vueI18n,
      router
    };
  }
};

/**
 * Creates a DOM element to mount the X Components app.
 *
 * @param snippetConfig - The snippet configuration.
 * @returns The DOM element.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getDomElement({ isolate }: SnippetConfig): Element {
  const container = document.createElement('div');
  container.classList.add('x-root-container');
  const domElement = document.createElement('div');
  container.appendChild(domElement);
  cssInjector.setHost(document.head);

  document.body.appendChild(container);
  return domElement;
}
