import { XPlugin } from '@empathyco/x-components';
import { QueriesPreviewXStoreModule } from '@empathyco/x-components/queries-preview';
export const fetchQueryPreview: QueriesPreviewXStoreModule['actions']['fetchQueryPreview'] = (
  _context,
  request
) => {
  const { query } = request;
  if (!query) {
    return null;
  }
  const { area, store, ...customExtraParams } = request.extraParams ?? {};
  const customRequest = {
    ...request,
    extraParams: store
      ? {
          ...customExtraParams,
          store
        }
      : {
          ...customExtraParams,
          area
        }
  };

  return XPlugin.adapter.search(customRequest, {
    id: `fetchQueryPreview-${query}`
  });
};
