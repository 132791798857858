import { createFetchAndSaveActions } from '@empathyco/x-components';
import { SearchActions } from '@empathyco/x-components/search';
import { CustomSearchActionContext } from '../types';
//@SuppressWarnings
//NOSONAR
const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  CustomSearchActionContext,
  any | null,
  any | null
>({
  fetch({ dispatch }, request) {
    return request
      ? dispatch('fetchExtraResultsResponse' as keyof SearchActions, request)
      : Promise.resolve(null);
  },
  onSuccess({ dispatch }, response) {
    if (response !== null) {
      dispatch('saveExtraResultsResponse', response);
    }
  }
});

/**
 * Implementation for {@link CustomSearchActionContext.fetchAndSaveExtraResultsResponse} action.
 *
 * @public
 */
export const fetchAndSaveExtraResultsResponse = fetchAndSave;

/**
 * Implementation for {@link CustomSearchActionContext.cancelFetchAndSaveExtraResultsResponse}.
 *
 * @public
 */
export const cancelFetchAndSaveExtraResultsResponse = cancelPrevious;
