import { CustomSearchXStoreModule } from '../types';
import { adapter } from '../../../adapter/adapter';

export interface StoreParams {
  area?: string;
  name?: string;
  lang: string;
}
/**
 * Default implementation for the {@link fetchStoreInfoResponse}.
 *
 * @param _context
 * @param params
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchStoreInfoResponse: CustomSearchXStoreModule['actions']['fetchStoreInfoResponse'] =
  (_context, params?: StoreParams) => {
    if (!params?.name) {
      return adapter.storeInfo({
        area: params?.area,
        lang: params?.lang
      });
    } else {
      return adapter.storeInfo({ name: params.name, lang: params.lang });
    }
  };
