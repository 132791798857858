import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { getEndpointRoute } from '../../utils/environment';

/**
 * Returns the store counter endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getRelatedPromptEndpoint(): string {
  return `${getEndpointRoute()}relatedprompts/mymotivemarketplace`;
}

export const relatedPrompts = endpointAdapterFactory<any, any>({
  endpoint: getRelatedPromptEndpoint(),
  responseMapper: schemaMapperFactory<any, any>({
    syntheticSuggestions: response => response.data?.relatedprompts ?? []
  })
});
