import CryptoES from 'crypto-es';

/**
 * Generating an encoded signature in order to enable URL signature checking for imgproxy,
 * given a key/salt pair secrets.
 *
 * @param imageUrl - The imageURL to use for the signature.
 * @returns The encoded signature.
 */
export function getEncodedSignature(imageUrl: string): string {
  const keyHex: string = process.env.VUE_APP_IMAGE_PROXY_KEY;
  const saltHex: string = process.env.VUE_APP_IMAGE_PROXY_SALT;

  const index = imageUrl.indexOf('?');
  const cleanImageUrl = index !== -1 ? imageUrl.substring(0, index) : imageUrl;

  const encodedWord = CryptoES.enc.Utf8.parse(cleanImageUrl);
  const base64ImgUrl = '/' + CryptoES.enc.Base64url.stringify(encodedWord);

  const hmac = CryptoES.algo.HMAC.create(CryptoES.algo.SHA256, CryptoES.enc.Hex.parse(keyHex));
  hmac.update(CryptoES.enc.Hex.parse(saltHex));
  hmac.update(base64ImgUrl);
  const base64url = CryptoES.enc.Base64url.stringify(hmac.finalize());

  return `${base64url}${base64ImgUrl}`;
}
