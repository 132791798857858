import { RouteConfig } from 'vue-router';

export default <RouteConfig[]>[
  {
    path: '/',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  },
  {
    path: '/about-us/',
    component: () => import('../pages/about-us.vue').then(d => d.default)
  },
  {
    path: '/legal-terms',
    component: () => import('../pages/legal-terms.vue').then(d => d.default)
  },
  {
    path: '/amagijon',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  },
  {
    path: '/amadrid',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  },
  {
    path: '/uk',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  },
  {
    path: '/contact',
    component: () => import('../pages/contact.vue').then(d => d.default)
  },
  {
    path: '/privacy-policy',
    component: () => import('../pages/privacy-policy.vue').then(d => d.default)
  },
  {
    path: '/sitemap',
    component: () => import('../pages/sitemap.vue').then(d => d.default)
  },
  {
    path: '/sobre-nosotros',
    component: () => import('../pages/sobre-nosotros.vue').then(d => d.default)
  },
  {
    path: '/legal',
    component: () => import('../pages/legal.vue').then(d => d.default)
  },
  {
    path: '/contacto',
    component: () => import('../pages/contacto.vue').then(d => d.default)
  },
  {
    path: '/politica-de-privacidad',
    component: () => import('../pages/politica-de-privacidad.vue').then(d => d.default)
  },
  {
    path: '*',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  }
];
