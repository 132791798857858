import {
  PlatformRecommendationsRequest,
  recommendationsRequestSchema
} from '@empathyco/x-adapter-platform';
import { RecommendationsRequest } from '@empathyco/x-types';

/**
 * Overrides {@link RecommendationsRequest} schema properties with
 * {@link PlatformRecommendationsRequest} extra properties.
 */
export const recommendationsRequestSchemaOverride = (): void => {
  recommendationsRequestSchema.$override<
    RecommendationsRequest,
    Partial<PlatformRecommendationsRequest>
  >({
    // TODO Top clicked demo endpoint breaks if it receives the scope parameter
    extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams
  });
};
