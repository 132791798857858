import {
  createWiring,
  namespacedWireCommit,
  namespacedWireCommitWithoutPayload,
  namespacedWireDispatch
} from '@empathyco/x-components';

const wireDispatch = namespacedWireDispatch('relatedPrompts');
const wireCommit = namespacedWireCommit('relatedPrompts');
const wireCommitWithoutPayload = namespacedWireCommitWithoutPayload('relatedPrompts');

const fetchAndSaveRelatedPromptsResponseWire = wireDispatch('fetchAndSaveRelatedPromptsResponse');
const setSelectedPromptWire = wireCommit('setSelectedPrompt');
const setSelectedQueryWire = wireCommit('setSelectedQuery');
const resetStateWire = wireCommitWithoutPayload('resetState');

/**
 * Wiring configuration for the {@link RelatedPromptsXModule | relatedPrompts module}.
 *
 * @internal
 */
export const relatedPromptsWiring = createWiring({
  SearchResponseChanged: {
    fetchAndSaveRelatedPromptsResponseWire
  },
  UserSelectedARelatedPrompt: { setSelectedPromptWire },
  UserSelectedARelatedPromptQuery: { setSelectedQueryWire },
  UserClearedQuery: { resetStateWire },
  UserPickedParams: { fetchAndSaveRelatedPromptsResponseWire },
  ResetRelatedPrompts: { resetStateWire },
  GetStorePreview: { fetchAndSaveRelatedPromptsResponseWire }
});
