import { RelatedPromptsState, RelatedPromptsXStoreModule } from './types';
import { fetchRelatedPromptsResponse } from './actions/fetch-related-prompts-response.action';
import {
  cancelFetchAndSaveRelatedPromptsResponse,
  fetchAndSaveRelatedPromptsResponse
} from './actions/fetch-and-save-related-prompts.action';
import { saveRelatedPromptsResponse } from './actions/save-related-prompts-response.action';

/**
 * {@link RelatedPromptsXStoreModule} For the relatedPrompts module.
 *
 * @internal
 */
export const relatedPromptsXStoreModule: RelatedPromptsXStoreModule = {
  state: () => ({
    status: 'initial',
    relatedPrompts: [],
    selectedPrompt: -1,
    selectedQuery: -1
  }),
  getters: {},
  mutations: {
    setRelatedPrompts(state, relatedPrompts) {
      state.relatedPrompts = relatedPrompts;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setSelectedPrompt(state, selectedPrompt) {
      state.selectedPrompt = selectedPrompt;
    },
    setSelectedQuery(state, selectedQuery) {
      state.selectedQuery = selectedQuery;
    },
    resetState(state: RelatedPromptsState) {
      state.selectedQuery = -1;
      state.selectedPrompt = -1;
      state.relatedPrompts = [];
    }
  },
  actions: {
    fetchRelatedPromptsResponse,
    cancelFetchAndSaveRelatedPromptsResponse,
    fetchAndSaveRelatedPromptsResponse,
    saveRelatedPromptsResponse
  }
};
