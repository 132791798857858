import { bannerSchema, promotedSchema, resultSchema } from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';
import { Dictionary } from '@empathyco/x-utils';
import { MyMotiveResult } from '../../models/entitites/result';
import { getEncodedSignature } from '../../../utils/encryption';
import { getEnvironment } from '../../../utils/environment';

/**
 * Auxiliar function to get the img proxy service URL.
 *
 * @returns The URL of the images proxy depending on the current app
 * enviroment (staging, prod and test by default).
 */
export function getProxyServiceURL(): string {
  const endpoint: Dictionary<string> = {
    live: process.env.VUE_APP_IMAGE_PROXY_SERVICE_PROD,
    staging: process.env.VUE_APP_IMAGE_PROXY_SERVICE_STAGING
  };

  const env = getEnvironment();

  return endpoint[env];
}

/**
 * Maps result images by prepending the proxy service URL to each one.
 *
 * @param images - The list of images to map.
 * @returns A list of mapped images.
 */
function mapImages(images: string[]): string[] {
  return images.map(imageURL => {
    if (imageURL.includes('motivemarket.com/') || imageURL.includes('imgproxy')) {
      return imageURL;
    }
    return getProxyServiceURL() + getEncodedSignature(imageURL);
  });
}

/**
 * Parses the description of a result.
 *
 * @param shortDescription - The short description.
 * @param description - The description.
 * @returns The parsed description.
 */
function parseDescription(shortDescription: string, description: string): string {
  if (shortDescription) {
    return shortDescription;
  }

  return description ? description.split('. ')[0] : '';
}

/**
 * Overrides {@link Result} schema properties with {@link MyMotiveResult}
 * extra properties.
 */
export const resultSchemaOverride = (): void => {
  resultSchema.$override<MyMotiveResult, Partial<Result>>({
    city: 'city',
    area: 'area',
    brand: 'brand',
    store: 'store',
    description: 'description',
    shortDescription: ({ shortDescription, description }) =>
      parseDescription(shortDescription, description),
    images: ({ images }) => mapImages(images),
    category: 'category',
    location: 'location',
    address: 'address'
  });
};

export const bannerSchemaOverride = (): void => {
  bannerSchema.$override<any, any>({
    image: ({ image_url }) => mapImages([image_url])[0]
  });
};

export const promotedSchemaOverride = (): void => {
  promotedSchema.$override<any, any>({
    image: ({ image_url }) => mapImages([image_url])[0]
  });
};
